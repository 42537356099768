.statsWindow > * > .MuiPaper-root {
    border: thin solid #666;
}

.stats > * > .title {
    font-weight: 700;
}

.stats > h1 {
    font-size: 1.6rem;
    margin-bottom: 0rem;
}

.stats > h2 {
    font-size:1.2rem;
    margin: .5rem 0;
    
}
.stats > * > * > * > h3 {
    font-size: 1rem;
    margin: .2rem auto;
}

.stats > div {
    margin-bottom: .5rem;
    margin-top: 0px;
    font-size: .8rem;
}

.stats > .todaysGame > *, .stats > .todaysGame > ul > li {
    margin-bottom: .5rem;
}

.stats > button, .stats > * > button, .stats > .gameType {
    font-size: .6rem;
    margin-top:1rem;
    width:100%;
}

.todaysGame > ul > li > .outcomeIcon {
    min-width: 2rem;
}
.todaysGame > ul > li.success {
    background-color: var(--game-success-color);
}

.todaysGame > ul > li.failure {
    background-color: var(--game-danger-color);
}
.todaysGame > ul > li > * > * > .levelPointsInStats {
    font-size: .8rem;
}


.todaysGame > ul > li.share {
    padding: 0;
}
.todaysGame > ul > li > button {
    font-size: .6rem;
    width:100%;
}
.todaysGame > ul > li > h2 {
    margin: auto;
}
