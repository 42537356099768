.appPage{
    font-family: var(--reading-font);
    font-weight: 400;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 1rem auto;
    max-width: 640px;
    padding: 0px;
    width: 80%;
    @media only screen and (max-width: 600px) {
      margin-top:.5rem;
    }
}

.appPage > * > .title {
    font-weight: 700;
}

.appPage > h1, h2, h3 {
    font-family: var(--headline-font);
}

.appPage > p {
    margin: .3rem 0;;
}

.appPage > h1 {
    font-size: 1.6rem;
    margin-bottom: .3rem;
}

.appPage > h2, .appPage > * > h2 {
    font-size:1.2rem;
    margin: .5rem 0 .2rem;
    text-align: left;
}
.appPage > h3, .appPage > * > h3, .modalContent > h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: .4rem 0 .2rem;
    text-align: left;
}

.appPage > div, .appPage > * > div {
    margin-bottom: .75rem;
    margin-top: 0px;
    font-size: .875rem;
    font-weight: 400;
}

.appPage > ul {
    margin-bottom: 0;
    margin-top: 0px;
    font-size: 1rem;
    font-weight: 400;
}

.appPage > * > li, .quickHelp > ul > li {
    font-size: .875rem;
    margin-bottom: .5rem;
}

.appPage > img, .appPage > * > img {
    padding-bottom: .5rem;
    max-width: 100%;
}

.quickHelp {
    padding: 1rem;
}

.quickHelp > ul {
    padding: .5rem 0;
    padding-inline-start: 1rem;
}

.modalHeader {
    align-items: center;
    background-color: black;
    display:flex;
    flex-wrap:wrap;    
}
.modalHeader > div {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-grow: 1;
}
.modalContent > .actions {
    display: flex;
    flex-direction: row;
}
.modalContent > .actions > button {
    flex: 1;
    margin: 1rem .5rem 0;
    padding: auto 0;
}