.outcome {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 1rem;
    padding: 1rem;
}


.outcome.victory {
    background-color: var( --game-success-color);
}

.outcome.defeat {
    background-color: var(--game-danger-color);
}
