.guessList {
    background-color:var(--guess-list-background-color);
    border-radius: 0 0 .4rem .4rem;
    color: #000;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 1rem;
}
.guessList > h2 {
    text-align: left;
}

.guessList > .warning {
    color: var(--game-warning-color);
}
.guessList > .danger {
    color: var(--game-danger-color);
}

.guessList > ul {
    padding-inline-start: 0px;
}
.guessList > ul > div {
    font-size: 1rem;
    border-bottom: 1px dotted #ccc;
    padding: 5px 0px;
}