.resultSpace {
    display: flex;
    flex-direction: row;
    font-size: 2rem;
    font-weight: 700;
    justify-content: center;
    margin: 1rem auto .2rem;
    width: 90%;
    text-align: center;
}

.MuiPaper-root.letterTile {
    background-color: rgb(0,0,0);
}
.letterTile {
    display: flex;
    justify-content: center;
    margin: 1%;
    user-select: none;
    width:6%;
}
.letterTile.success {
    background-color: var(--game-success-color);
}
.letterTile.failure {
    background-color: var(--game-danger-color);
}
.letterTile.waiting {
    color: rgb(0,0,0);
}

.letterCount {
    color: var(--hint-decor-color);
    font-size: x-small;
    font-weight: 700;
    text-align: center;
    margin-bottom: .5rem;
}

@media only screen and (max-width: 600px) {
    .resultSpace {
        font-size: 1.2rem;
    }
    .letterTile {
        margin: 1%;
        padding:1%;
        width:5%;
    }
}

.usedLetters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin:auto;
}
.usedLetters > .usedLetter {
    background-color: var(--game-danger-color);
    font-size: .8rem;
    margin: .2rem;
    padding: .15rem;
    text-align: center;
    width: .6rem;
}
@media only screen and (max-width: 600px) {
    .usedLetters {
        text-align:center;
    }
}