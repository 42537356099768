.gameMenu {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 50%;
}

.gameMenu > * > * > button {
    padding: .4rem .4rem;
    min-width: 10px;
}
