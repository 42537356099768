.clue {
    background-color: var(--clue-background-color);
    color: #000;
    padding: 1rem;

}
.clue > h2 {
    text-align: left;
    margin-top: 0;
}

.clue > .title{
    font-size: .8rem;
    font-weight: 400;
    margin: .5rem 0 0 0;
}

