.appMenu {
   flex: 1;
}

.appMenu > button {
    background-color: var(--main-color);
    opacity: .5;
    padding: .8rem .8rem;
    position:fixed;
    top: 3px;
}
