.letterDeployment {
    display: flex;
    flex-direction: row;
    font-size: 2rem;
    font-weight: 700;
    height: 2rem;
    margin: 1rem auto .2rem;
    width: 90%;
    position: absolute;
    top: .3rem;
    left: 1rem;
}

.letterDeployment > .match {
    background-color: var(--game-success-color);
}

.letterDeployment > .reject {
    background-color: var(--game-danger-color);
}

.letterDeployment > .letterTile {
    font-size: 1.4rem;
    height: fit-content;
    padding: 0 .1rem;
    position: absolute;
    z-index: 10;
}