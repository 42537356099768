
.levelComplete {
  animation: levelComplete 2s linear;
  animation-fill-mode: backwards;
}

.allLettersFound {
  animation: allLettersFound 2s linear;
  animation-fill-mode: backwards;
}
  
/* Keyframes */
@keyframes levelComplete {
  0%, 7% {
    transform: rotateZ(0) scale(1);
  }
  15% {
    transform: rotateZ(-25deg) scale(.75);
  }
  20% {
    transform: rotateZ(25deg) scale(1.05);
  }
  25% {
    transform: rotateZ(-20deg) scale(1.15);
  }
  30% {
    transform: rotateZ(20deg) scale(1.2);
  }
  45% {
    transform: rotateZ(-15deg) scale(1.15);
  }
  65% {
    transform: rotateZ(12deg) scale(1.1);
  }
  75% {
    transform: rotateZ(-6deg) scale(1.05);
  }
  85% {
    transform: rotateZ(2deg) scale(1);
  }
  100% {
    transform: rotateZ(0) scale(1);
  }
}

@keyframes allLettersFound {
  0%, 7% {
    transform: scale(1);
  }
  10% {
    transform: scale(.75);
  }
  25% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(.8);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@media (prefers-reduced-motion: reduce) {
  .levelComplete {
    animation: simplePop .5s linear;
    animation-fill-mode: backwards;
  }

  /* Keyframes */
@keyframes simplePop {
  0% {
    transform: translateY(0px);
  }
  45% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0px);
  }
}
}