:root { 
  --headline-font: 'Roboto', sans-serif;
  --reading-font: 'Roboto', "system-ui", "Segoe UI", Roboto, Oxygen, sans-serif;
  --game-font: 'Roboto', sans-serif;

  --reading-text-color: #A6B4CB;
  --main-color: #D77244;
  --text-link-color: #000;
  --subtitle-color: #FFC2BB;
  
  --game-success-color: #7DA86F;
  /* Danger signifies loss or impending lose  */
  --game-danger-color: #A94A49;
  /* Danger reserved ONLY for the guess list, when the user passes a threshold  */
  --game-warning-color: #B35F32;
  --game-area-background-color: #121212;
  --button-color: #BD5B59;
  --button-text-color: #E9FFE3;
  --button-selected-color: #BACCB6;
  --button-selected-text-color: #000;
  --button-disabled-color: #E9FFE3;
  --button-disabled-text-color: #666;
  --clue-background-color: #BACCB6;
  --light-text: #758071;
  --toaster-background-color: #758071;
  --toaster-text-color: #FFF;
  --hint-decor-color: #999;

  /* Lose these unless we restore the keyboard */
  --guess-list-background-color: #E9FFE3;
  --keyboard-background-color: #393838;
  --keyboard-text-area-color: #FFF;
  --keyboard-default-key-color:#666;
  --keyboard-found-key-color:#7DA86F;
  --keyboard-missed-key-color:#B35F32;
  --keyboard-utility-key-color: #CCC;
  --keyboard-key-text-color: #CCC;
}

.App {
  text-align: center;
}

.hidden {
  display: none;
}

.rootl {
  color: var(--reading-text-color);
  font-family: var(--headline-font);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: auto;
  position: relative;
  width: 100%;
}

.rootl > .rootl-header {
  align-items: center;
  background-color: var(--main-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  top:0;
  width:100%; 
  z-index: 1;
}

.rootl > .rootl-header > h1 {
  margin: 0;
}

.rootl > .rootl-header > h1 > a {
  color: #FFF;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 2.4rem;
  justify-content: center;
  left: .4rem;
  padding-bottom: .2rem;
  position: relative;
  margin: 0;
  top: .3rem;
}

.rootl > .rootl-header > .rightFill {
  flex: 1;
}

.rootl > .subHead {
  color: var(--subtitle-color);
  font-size: .8rem;
  font-weight: 400;
  padding: 0 0 8px;
  text-align: center;
}
.rootl > .rootl-header > h1 > a > span img {
  
  border-radius: 4px;
  height: 2.5rem;
  left: 0;
  padding: 2px;
  position: relative;
  top: .25rem;
  width: 2.5rem;
}


.rootl > .copyright {
  margin: 10px auto;
  color: var(--subtitle-color);
}

.rootl > .gameArea {
  font-family: var(--game-font);
  font-weight: 400;
  display: flex;
  height: calc(100% - (170px + 130px));
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto 1rem;
  max-width: 800px;
  padding: 0px;
  width: 100%;
}
.rootl > .gameArea > .levelSelectorAndScore {
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 80%;

}
@media only screen and (max-width: 600px) {
  .rootl > .gameArea > .levelSelectorAndScore {
    margin-bottom:1rem;
    margin-top:0;
    flex-direction: column;
  }
}

  .rootl > .gameArea  > .levelSelectorAndScore > .levelSelector {
    flex-basis: 33%;
    margin-right: auto;
  }
  @media only screen and (max-width: 600px) {
    .rootl > .gameArea  > .levelSelectorAndScore > .levelSelector {
      flex-basis: 100%;
      margin: 0 auto 0 auto;
    }
  }

  .rootl > .gameArea  > .levelSelectorAndScore > .levelSelector > .win {
    background-color: #758071;
    margin: 20px;
  }
  .rootl > .gameArea  > .levelSelectorAndScore > .levelSelector > .loss {
  background-color: #BD5B59;
  }

  .rootl > .gameArea  > .levelSelectorAndScore > .gameId > a {
    text-decoration: none;
    cursor: pointer;
  }

  .rootl > .gameArea  > .levelSelectorAndScore > .score, .gameId {
    flex-basis: 33%;
    font-size: 1.2rem;
    margin-left: auto;
    margin: auto 0;
  }
  @media only screen and (max-width: 600px) {
    .rootl > .gameArea  > .levelSelectorAndScore > .score, .gameId {
      flex-basis: 100%;
      font-size: .9rem;
      padding: .4rem;
    }
  }
  .rootl > .gameArea  > .levelSelectorAndScore > .score {
    text-align: right;
    @media only screen and (max-width: 600px) {
      margin: .2rem auto 1.2rem;
    }
  }
  .rootl > .gameArea  > .levelSelectorAndScore > .gameId {
    text-align: center;
    font-weight: 700;
  }

  .announcement {
    right: -4.5em;
    overflow: hidden;
    position: fixed;
    transform: rotate(45deg);
    width: 20em;
    top: 2.75em;

    animation: announcement 1s linear;
    animation-fill-mode: backwards;
    animation-delay: 2s;
  }

  /* Keyframes */
@keyframes announcement {
  0% {
    margin-top: -140px;
  }

  10% {
    margin-top: 0px;
  }

  30% {
    margin-top: -10px;
  }

  70% {
    margin-top: 0px;
  }
}
