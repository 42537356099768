.game {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 80%;
}
.MuiPaper-root.game {
    background-color: rgb(40, 40, 40);
}

.game > .gameOptions {
    padding-top: .2rem;
}
.game > .gameOptions > button {
    margin: .5rem .5rem 0 auto;
    float: right;
}
.game > .textInputAndDeployment {
    display: inline-flex;
    flex-direction: column;
    margin: .5rem;
    position: relative;
}

.game > .gameOptions > * > * > .MuiSelect-select {
    left: -2px;
    padding: 0;
    position: relative;
}

.clueAndGuessList {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    margin:auto;
}